import React from 'react';
import cn from 'classnames';
import ContentLoader from 'react-content-loader';
import { useRecoilValueLoadable, useRecoilRefresher_UNSTABLE } from 'recoil';
import { money } from 'common/utils/formatters';
import {
  getPriceByPostcodeQuery,
  getRepaymentInfoByPostcodeQuery,
} from 'common/store/user-location-state';
import styles from './index.module.scss';
import { GenericModal } from '~/common/components/generic-modal';
import { usePortals } from 'react-portal-hook';
import {
  DetailedPricing,
  RepaymentsDisclaimer1,
  RepaymentsDisclaimer2,
  RepaymentsDisclaimer4,
  RepaymentsDisclaimerForComparisonRate,
} from '~/pages/showroom/components/price-details/RepaymentDisclaimers';
import { formatNumber } from 'accounting';

interface Props {
  type: 'RDP' | 'Finance' | 'InlineRDP';
  labelClass?: string;
  containerClass?: string;
  disclaimer?: string;
  variantId?: string;
  pimIdentifier?: string;
  modelName?: string;
  isFinanceFormInputPersonalised: boolean;
  recommendedRdpLabel?: string;
  recommendedRdpLabelClass?: string;
  priceClass?: string;
}

export const CompareRangePriceDetail = React.memo<Props>(
  ({
    type,
    variantId,
    pimIdentifier,
    modelName,
    isFinanceFormInputPersonalised,
    recommendedRdpLabel,
    recommendedRdpLabelClass,
    containerClass,
    labelClass,
    priceClass,
  }) => {
    const price = useRecoilValueLoadable(getPriceByPostcodeQuery(variantId));
    const refresh = useRecoilRefresher_UNSTABLE(
      getPriceByPostcodeQuery(variantId)
    );
    const repaymentInfo = useRecoilValueLoadable(
      getRepaymentInfoByPostcodeQuery(pimIdentifier)
    );

    const defaultPriceClassName = cn(
      styles[`priceText_${type.toLocaleLowerCase()}`]
    );

    const portalManager = usePortals();
    const showFinanceDisclaimer = (element: any, title: string) => {
      portalManager.open((portal) => (
        <GenericModal
          title={title}
          element={element}
          closeModal={portal.close}
        />
      ));
    };

    return (
      <div className={cn(styles.priceDetails, containerClass)}>
        {type === 'RDP' ? (
          <div>
            <label className={cn(styles.defaultLabelClass, labelClass)}>
              From
            </label>
            {price.state === 'loading' ? (
              <div className={styles.priceText}>
                <ContentLoader
                  speed={0.3}
                  width={155}
                  height={55}
                  viewBox="0 0 155 55"
                  backgroundColor="#aebbd6"
                  foregroundColor="#ecebeb"
                  style={{ verticalAlign: 'middle' }}
                >
                  <rect x="0" y="11" rx="3" ry="3" width="100" height="4" />
                  <rect x="0" y="21" rx="3" ry="3" width="50" height="4" />
                  <rect x="0" y="45" rx="3" ry="3" width="130" height="4" />
                </ContentLoader>
              </div>
            ) : price.state === 'hasError' ? (
              <div className={cn(styles.priceText, styles.priceTextError)}>
                <p>
                  An error occurred loading <br />
                  RDP in your area.
                </p>
                <p onClick={refresh}>Click here to reload</p>
              </div>
            ) : (
              <div
                onClick={() => {
                  showFinanceDisclaimer(
                    DetailedPricing(
                      modelName ?? '',
                      price.contents.rdp,
                      price.contents.mlp
                    ),
                    ''
                  );
                }}
              >
                <p className={styles.priceText}>
                  <span className={cn(defaultPriceClassName, priceClass)}>
                    {money(price.contents.rdp, 0)}
                  </span>
                </p>
                <p
                  className={cn(
                    styles.priceText2,
                    styles.priceText2_rdp,
                    recommendedRdpLabelClass
                  )}
                >
                  <span>
                    {recommendedRdpLabel ?? 'Recommended Driveaway Price'}
                    <sup>
                      <i className="icon icon--info" />
                    </sup>
                  </span>
                </p>
              </div>
            )}
          </div>
        ) : type === 'InlineRDP' ? (
          <div>
            <label className={cn(styles.defaultLabelClass, labelClass)}>
              From
            </label>
            {price.state === 'loading' ? (
              <div className={styles.priceText}>
                <ContentLoader
                  speed={0.3}
                  width={155}
                  height={55}
                  viewBox="0 0 155 55"
                  backgroundColor="#aebbd6"
                  foregroundColor="#ecebeb"
                  style={{ verticalAlign: 'middle' }}
                >
                  <rect x="0" y="11" rx="3" ry="3" width="100" height="4" />
                  <rect x="0" y="21" rx="3" ry="3" width="50" height="4" />
                  <rect x="0" y="45" rx="3" ry="3" width="130" height="4" />
                </ContentLoader>
              </div>
            ) : price.state === 'hasError' ? (
              <div className={cn(styles.priceText, styles.priceTextError)}>
                <p>
                  An error occurred loading <br />
                  RDP in your area.
                </p>
                <p onClick={refresh}>Click here to reload</p>
              </div>
            ) : (
              <div
                onClick={() => {
                  showFinanceDisclaimer(
                    DetailedPricing(
                      modelName ?? '',
                      price.contents.rdp,
                      price.contents.mlp
                    ),
                    ''
                  );
                }}
              >
                <p className={styles.priceText}>
                  <span className={defaultPriceClassName}>
                    {money(price.contents.rdp, 0)}
                  </span>
                </p>
                <p className={cn(styles.priceText2, styles.priceText2_rdp)}>
                  RDP
                  <sup>
                    <i className="icon icon--info" />
                  </sup>
                  {/*
                  Uncomment this when GFV is back
                  <span>Recommended</span>
                  <span>
                  Driveaway Price
                  <sup>
                    <i className="icon icon--info" />
                  </sup>
                </span> */}
                </p>
              </div>
            )}
          </div>
        ) : type === 'Finance' ? (
          <div className={styles.financeWrapper}>
            <div>
              {!(
                repaymentInfo.contents.isGfvNotAvailableError ||
                repaymentInfo.contents.isDepositTooHighError ||
                repaymentInfo.contents.isGeneralError
              ) && (
                <label className={cn(styles.defaultLabelClass, labelClass)}>
                  From
                </label>
              )}
              {repaymentInfo.state === 'loading' ? (
                <div className={styles.priceText}>
                  <ContentLoader
                    speed={0.3}
                    width={155}
                    height={55}
                    viewBox="0 0 155 55"
                    backgroundColor="#aebbd6"
                    foregroundColor="#ecebeb"
                    style={{ verticalAlign: 'middle' }}
                  >
                    <rect x="0" y="11" rx="3" ry="3" width="100" height="4" />
                    <rect x="0" y="21" rx="3" ry="3" width="50" height="4" />
                    <rect x="0" y="45" rx="3" ry="3" width="130" height="4" />
                  </ContentLoader>
                </div>
              ) : repaymentInfo.contents.isGfvNotAvailableError ||
                repaymentInfo.contents.isDepositTooHighError ||
                repaymentInfo.contents.isGeneralError ? (
                <div className={cn(styles.priceText, styles.priceTextError)}>
                  <p>{repaymentInfo.contents.errorDescription}</p>
                </div>
              ) : (
                <div>
                  {!(
                    repaymentInfo.contents.isGfvNotAvailableError ||
                    repaymentInfo.contents.isDepositTooHighError ||
                    repaymentInfo.contents.isGeneralError
                  ) ? (
                    <>
                      <div
                        onClick={() => {
                          showFinanceDisclaimer(
                            RepaymentsDisclaimer2(
                              money(price.contents.rdp),
                              repaymentInfo.contents.term,
                              repaymentInfo.contents.interestRate,
                              money(repaymentInfo.contents.deposit, 2),
                              repaymentInfo.contents.paymentFrequency,
                              repaymentInfo.contents.monthlyRepayment,
                              repaymentInfo.contents.financeProductId ===
                                'FIN_PROD_AUS_SUB_GFV',
                              formatNumber(repaymentInfo.contents.mileage),
                              money(repaymentInfo.contents.residualValue, 2)
                            ),
                            ''
                          );
                        }}
                      >
                        <p className={styles.priceText}>
                          <span className={styles.priceText_finance}>
                            {money(repaymentInfo.contents.paymentValue, 0)} p/
                            {repaymentInfo.contents.paymentFrequencyString}
                            <sup>2</sup>
                          </span>
                        </p>
                      </div>
                      {repaymentInfo.contents.residualValue > 0 && (
                        <div
                          onClick={() => {
                            showFinanceDisclaimer(RepaymentsDisclaimer1(), '');
                          }}
                        >
                          <p className={styles.gfvText}>
                            with Subaru GFV
                            <sup>1</sup>
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <p className={styles.priceText2}>
                      {repaymentInfo.contents.errorDescription}
                    </p>
                  )}
                </div>
              )}
            </div>

            {!(
              repaymentInfo.contents.isGfvNotAvailableError ||
              repaymentInfo.contents.isDepositTooHighError ||
              repaymentInfo.contents.isGeneralError
            ) && (
              <div>
                <label>&nbsp;</label>
                {repaymentInfo.state === 'loading' ? (
                  <div className={styles.priceText}>
                    <ContentLoader
                      speed={0.3}
                      width={155}
                      height={55}
                      viewBox="0 0 155 55"
                      backgroundColor="#aebbd6"
                      foregroundColor="#ecebeb"
                      style={{ verticalAlign: 'middle' }}
                    >
                      <rect x="0" y="11" rx="3" ry="3" width="100" height="4" />
                      <rect x="0" y="21" rx="3" ry="3" width="50" height="4" />
                      <rect x="0" y="45" rx="3" ry="3" width="130" height="4" />
                    </ContentLoader>
                  </div>
                ) : repaymentInfo.contents.isGfvNotAvailableError ||
                  repaymentInfo.contents.isDepositTooHighError ||
                  repaymentInfo.contents.isGeneralError ? (
                  <div className={cn(styles.priceText, styles.priceTextError)}>
                    <p>{repaymentInfo.contents.errorDescription}</p>
                  </div>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        showFinanceDisclaimer(
                          RepaymentsDisclaimer4(isFinanceFormInputPersonalised),
                          ''
                        );
                      }}
                    >
                      <p className={styles.priceText}>
                        <span className={styles.priceText_finance}>
                          {repaymentInfo.contents.comparisonRate}%
                        </span>
                        <span>
                          p.a<sup>4</sup>
                        </span>
                      </p>
                    </div>
                    <div
                      className="showroom__finance_comparison-rate-line-two"
                      onClick={() => {
                        showFinanceDisclaimer(
                          RepaymentsDisclaimerForComparisonRate(),
                          ''
                        );
                      }}
                    >
                      Comparison rate
                      <i className="icon icon--info" />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
);
