import React from 'react';
import cn from 'classnames';
import Carousel from 'react-multi-carousel';
import { RecoilRoot } from 'recoil';
import { PortalProvider } from 'react-portal-hook';
import { VehicleModel } from './components/model-details';
import { SvgIcon } from '~/common/components/ui-elements';
import { ModelEntry } from './types';
import ModelRangeTab from './components/model-range-tab';
import styles from './index.module.scss';

interface SubaruModelRangeProps {
  contentId: number;
  modelEntries: ModelEntry[];
}

const SubaruModelRange = React.memo<SubaruModelRangeProps>(
  ({ modelEntries }) => {
    const carouselRef = React.useRef<Carousel>(null);
    const [activeEntries, setActiveEntries] = React.useState<ModelEntry[]>([]);

    const onCategorySelect = (category: string) => {
      carouselRef.current?.goToSlide(0);
      setActiveEntries(
        modelEntries.filter((x) => x.categoryTitle === category)
      );
    };

    return (
      <RecoilRoot>
        <PortalProvider>
          <div className={styles.subaruModelRange}>
            <ModelRangeTab
              modelEntries={modelEntries}
              onSelect={onCategorySelect}
            />

            <div className={styles.vehicleModels_desktop}>
              {activeEntries.map((modelEntry, index) => (
                <VehicleModel key={index} model={modelEntry} />
              ))}
            </div>

            <div className={styles.vehicleModels_mobile}>
              <Carousel
                ref={carouselRef}
                arrows
                swipeable
                centerMode={false}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
                containerClass={cn(
                  styles.carouselContainer,
                  activeEntries.length === 1 && styles.carouselContainerCenter
                )}
                responsive={{
                  tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 2,
                    slidesToSlide: 2,
                  },
                  mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                    slidesToSlide: 1,
                  },
                }}
              >
                {activeEntries.map((modelEntry, index) => (
                  <VehicleModel key={index} model={modelEntry} />
                ))}
              </Carousel>
            </div>
          </div>
        </PortalProvider>
      </RecoilRoot>
    );
  }
);

export default SubaruModelRange;

interface ArrowsProps {
  onClick?: () => void;
}
const CustomLeftArrow = ({ onClick }: ArrowsProps) => {
  return (
    <SvgIcon
      className={styles.customLeftArrow}
      type="chevronLeft"
      size={2}
      onClick={onClick}
    />
  );
};

const CustomRightArrow = ({ onClick }: ArrowsProps) => {
  return (
    <SvgIcon
      className={styles.customRightArrow}
      type="chevronRight"
      size={2}
      onClick={onClick}
    />
  );
};
