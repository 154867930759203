import React from 'react';
import cn from 'classnames';
import { ModelEntry } from '../../types';
import styles from './index.module.scss';

interface ModelRangeTabProps {
  modelEntries: ModelEntry[];
  onSelect: (category: string) => void;
}

const ModelRangeTab = React.memo<ModelRangeTabProps>(
  ({ modelEntries, onSelect }) => {
    const [activeTab, setActiveTab] = React.useState<string>('');

    const categories = React.useMemo(
      () =>
        Array.from(new Set(modelEntries.map((model) => model.categoryTitle))),
      [modelEntries]
    );

    const onTabSelect = React.useCallback((category: string) => {
      setActiveTab(category);
      onSelect(category);
    }, []);

    React.useEffect(() => {
      if (!!modelEntries && modelEntries.length > 0) {
        onTabSelect(categories[0]);
      }
    }, [categories]);

    return (
      <div className={styles.modelRangeTab}>
        <ul>
          {categories.map((category) => (
            <li
              key={category}
              onClick={() => onTabSelect(category)}
              className={cn(activeTab === category && styles.tabActive)}
            >
              <p>{category}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  }
);

export default ModelRangeTab;
